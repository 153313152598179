import * as React from 'react'

import { useInputControl } from '@conform-to/react'

import { cn } from '@biogroup/utils/cn'

import { Checkbox, type CheckboxProps } from './checkbox.tsx'
import { ErrorList, type ListOfErrors } from './error-list.tsx'
import { Label } from './label.tsx'

export function CheckboxField({
  labelProps,
  buttonProps,
  errors,
  className,
}: {
  labelProps: JSX.IntrinsicElements['label']
  buttonProps: CheckboxProps & {
    name: string
    form: string
    value?: string
  }
  errors?: ListOfErrors
  className?: string
}) {
  const { key, defaultChecked, ...checkboxProps } = buttonProps
  const fallbackId = React.useId()
  const checkedValue = buttonProps.value ?? 'on'
  const input = useInputControl({
    key,
    name: buttonProps.name,
    formId: buttonProps.form,
    initialValue: defaultChecked ? checkedValue : undefined,
  })
  const id = buttonProps.id ?? fallbackId
  const errorId = errors?.length ? `${id}-error` : undefined

  return (
    <div className={cn('flex flex-col gap-y-2', className)}>
      <div className="flex items-center gap-3">
        <Checkbox
          {...checkboxProps}
          id={id}
          aria-invalid={errorId ? true : undefined}
          aria-describedby={errorId}
          aria-label={
            typeof labelProps.children === 'string'
              ? labelProps.children
              : checkboxProps['aria-label']
                ? checkboxProps['aria-label']
                : undefined
          }
          checked={input.value === checkedValue}
          onCheckedChange={state => {
            input.change(state.valueOf() ? checkedValue : '')
            buttonProps.onCheckedChange?.(state)
          }}
          onFocus={event => {
            input.focus()
            buttonProps.onFocus?.(event)
          }}
          onBlur={event => {
            input.blur()
            buttonProps.onBlur?.(event)
          }}
          type="button"
        />
        <Label {...labelProps} htmlFor={id} className="font-semibold" />
      </div>
      {errorId ? <ErrorList id={errorId} errors={errors} /> : null}
    </div>
  )
}
